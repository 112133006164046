
import React from "react";

function ScrollIndicator({className}) {
  return (
    <svg className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="59"
      fill="none"
      viewBox="0 0 51 59"
    >
<path d="M0.576 54.3295L1.776 53.4335C2.384 54.3935 3.312 54.9855 4.592 54.9855C5.76 54.9855 6.688 54.3455 6.688 53.2895C6.688 52.3775 6.112 51.7695 5.056 51.3695L3.552 50.7935C1.776 50.1375 0.976 49.2095 0.976 47.6575C0.976 45.8015 2.496 44.6335 4.528 44.6335C5.952 44.6335 7.136 45.2735 7.84 46.1855L6.752 47.1455C6.176 46.4575 5.472 46.0255 4.496 46.0255C3.376 46.0255 2.448 46.6495 2.448 47.5935C2.448 48.5535 3.008 48.9855 4.16 49.4175L5.536 49.9455C7.216 50.5855 8.176 51.5455 8.176 53.2255C8.176 55.1615 6.672 56.3775 4.56 56.3775C2.688 56.3775 1.168 55.4975 0.576 54.3295ZM10.1398 56.1855V44.1855H11.5158V51.3055L15.0038 48.1855H16.8278L12.8918 51.7215L17.7078 56.1855H15.8518L11.5158 52.1855V56.1855H10.1398ZM18.7648 56.1855V48.1855H20.1408V49.7375C20.4608 48.7135 21.4528 48.1055 22.3808 48.1055C22.5888 48.1055 22.7808 48.1215 23.0048 48.1695V49.5935C22.7488 49.4975 22.5248 49.4655 22.2528 49.4655C21.2448 49.4655 20.1408 50.3455 20.1408 52.2175V56.1855H18.7648ZM32.0709 52.1855C32.0709 54.4895 30.2949 56.3455 27.8949 56.3455C25.4789 56.3455 23.7029 54.4895 23.7029 52.1855C23.7029 49.8815 25.4789 48.0255 27.8949 48.0255C30.2949 48.0255 32.0709 49.8815 32.0709 52.1855ZM25.1109 52.1855C25.1109 53.7855 26.2309 55.0655 27.8949 55.0655C29.5589 55.0655 30.6629 53.7855 30.6629 52.1855C30.6629 50.5855 29.5589 49.3055 27.8949 49.3055C26.2309 49.3055 25.1109 50.5855 25.1109 52.1855ZM34.046 56.1855V44.1855H35.422V56.1855H34.046ZM38.0304 56.1855V44.1855H39.4064V56.1855H38.0304ZM41.3908 52.1855C41.3908 49.8975 43.0707 48.0255 45.3108 48.0255C46.5108 48.0255 47.5988 48.6495 48.1268 49.4175V48.1855H49.5028V56.1855H48.1268V54.9535C47.5988 55.7215 46.5108 56.3455 45.3108 56.3455C43.0707 56.3455 41.3908 54.4735 41.3908 52.1855ZM48.2388 52.1855C48.2388 50.5695 47.1348 49.2895 45.5188 49.2895C43.9028 49.2895 42.7828 50.5695 42.7828 52.1855C42.7828 53.8015 43.9028 55.0815 45.5188 55.0815C47.1348 55.0815 48.2388 53.8015 48.2388 52.1855Z" fill="white"/>
<path d="M24.9999 30.6668C22.1709 30.6668 19.4578 29.543 17.4574 27.5426C15.4571 25.5422 14.3333 22.8291 14.3333 20.0002V12.0002C14.3333 9.17119 15.4571 6.45808 17.4574 4.45769C19.4578 2.4573 22.1709 1.3335 24.9999 1.3335C27.8289 1.3335 30.542 2.4573 32.5424 4.45769C34.5428 6.45808 35.6666 9.17119 35.6666 12.0002V20.0002C35.6666 22.8291 34.5428 25.5422 32.5424 27.5426C30.542 29.543 27.8289 30.6668 24.9999 30.6668Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
<path data-anim d="M25 9.3335V16.0002" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
    </svg>
  );
}

export default ScrollIndicator;
