import React from "react";
import css from "./Hero.module.scss";
import { AlignOptions } from "../../Elements/AlignOptions/AlignOptions.jsx";
import { setHost } from "../../../util/setHost.js";
import { BackgroundColorOptions } from "./../../Elements/BackgroundColorOptions/BackgroundColorOptions.jsx";
import ScrollIndicator from "./ScrollIndicator.jsx";
import { Visibility } from "../../Elements/Visibility.tsx";

export const ImageHero = ({
  backgroundHeight,
  headerVertical,
  headerHorizontal,
  backgroundImage,
  mobileBackgroundImage,
  headerOptAlign,
  headerOptSize,
  headerOptColor,
  header,
  headerPlacementVertical,
  colorGradient,
  backgroundSize,
  headerTextAlign,
  backgroundColor,
}) => {
  const host = setHost();
  const headerAlign = AlignOptions({ optionAlign: headerTextAlign });
  const backgroundOptionColor = BackgroundColorOptions({
    optionColor: backgroundColor?.colors ?? 'Tint Color Coolgrey 100',
  });

  function Element({ tag, cssClass, text }) {
    const className = `${cssClass} ${tag?.css} ${css?.heading}`;

    return React.createElement(tag?.tag, { className: className }, text);
  }

  const evaluateBackgroundImage = (imageUrl, fallbackUrl) => {
    if (imageUrl) {
      return `url(${host}${imageUrl})`;
    }

    if(fallbackUrl) {
      return `url(${host}${fallbackUrl}`
    }

    return unset;
  };

  return (
    <>
      <div
        className={` ${css.hero} ${backgroundHeight} ${backgroundOptionColor} full`}
      >
        <Visibility on="desktop"> 
          <div className={`${css.content} ${backgroundHeight} ${headerOptAlign} ${headerVertical} ${headerHorizontal}`}
          style={{
            backgroundImage: evaluateBackgroundImage(backgroundImage?.data?.attributes?.url, mobileBackgroundImage?.data?.attributes?.url)
          }}
        >
          <Element
            tag={headerOptSize}
            cssClass={`${headerOptColor} ${headerHorizontal} ${headerAlign}`}
            text={header ?? ""}
          />
        </div>
        </Visibility>
        <Visibility on="mobile"> 
        <div
          className={`show-in-mobile ${css.content} ${backgroundHeight} ${headerOptAlign} ${headerVertical} ${headerHorizontal}`}
          style={{
            backgroundImage: evaluateBackgroundImage(mobileBackgroundImage?.data?.attributes?.url, backgroundImage?.data?.attributes?.url)
          }}
        >
          <Element
            tag={headerOptSize}
            cssClass={`${headerOptColor} ${headerHorizontal} ${headerAlign}`}
            text={header ?? ""}
          />
        </div>
        </Visibility>
        <div
          className={css.bottomElm}
          style={{
            display: headerPlacementVertical === "Bottom" ? "block" : "block",
          }}
        >
          <>
            <div
              className={css.gradient}
              style={{
                backgroundImage: `linear-gradient(180deg, transparent, ${colorGradient} 100%)`,
              }}
            >
              {backgroundSize === "Large" && (
                <ScrollIndicator className={css.scrollIndicator}/>
              )}
            </div>
            <div
              className={css.box}
              style={{ backgroundColor: colorGradient }}
            />
          </>
        </div>
      </div>
    </>
  );
};
