// import React from 'react';
import css from "./Hero.module.scss";
import { ImageHero } from "./ImageHero.jsx";
import { VideoHero } from "./VideoHero.jsx";

import { ColorOptions } from "./../../Elements/ColorOptions/ColorOptions.jsx";
import { AlignOptions } from "../../Elements/AlignOptions/AlignOptions.jsx";
import { HeaderSizeOption } from "./../../Elements/HeaderSizeOption/HeaderSizeOption.jsx";
import { isVideoMimeType, isVideoUrl } from "../../../util/helper.js";
import { VimeoHero } from "./VimeoHero.jsx";
// import scrollIndicator from "./../../Assets/scroll_indicator.svg";

export const Hero = ({
  backgroundImage,
  mobileBackgroundImage,
  backgroundSize,
  header,
  headerPlacementHorizontal,
  headerPlacementVertical,
  headerSize,
  headerAlign,
  headerColor,
  colorGradient,
  headerTextAlign,
  backgroundColor,
  vimeo_url,
}) => {

  if (header === undefined) {
    console.error(
      "Content is undefined in Hero, returning nothing to UI. Try adding content to the block."
    );
    return null;
  }

  const headerOptColor = ColorOptions({ optionColor: headerColor?.colors });
  const headerOptAlign = AlignOptions({ optionAlign: headerAlign?.textAlign });
  const headerOptSize = HeaderSizeOption({optionSize: headerSize?.headerSize});

  const backgroundHeight = (() => {
    switch (backgroundSize) {
      case "Small":
        return css.small;
      case "Medium":
        return css.medium;
      case "Large":
        return css.large;
      default:
        return null;
    }
  })();

  const headerVertical = (() => {
    switch (headerPlacementVertical) {
      case "Middle":
        return css.middle;
      case "Bottom":
        return css.bottom;
      default:
        return null;
    }
  })();

  const headerHorizontal = (() => {
    switch (headerPlacementHorizontal) {
      case "Left":
        return css.left;
      case "Center":
        return css.center;
      case "Right":
        return css.right;
      default:
        return null;
    }
  })();
  if(vimeo_url){
   return <VimeoHero
      headerVertical={headerVertical}
      headerHorizontal={headerHorizontal}
      headerOptAlign={headerOptAlign}
      headerOptSize={headerOptSize}
      headerOptColor={headerOptColor}
      headerPlacementVertical={headerPlacementVertical}
      header={header}
      vimeo_url={vimeo_url}
      colorGradient={colorGradient}
      headerTextAlign={headerTextAlign?.textAlign}
    />
  }

  return isVideoMimeType(backgroundImage?.data?.attributes?.mime) ? (
    <VideoHero
      backgroundHeight={backgroundHeight}
      headerVertical={headerVertical}
      headerHorizontal={headerHorizontal}
      headerOptAlign={headerOptAlign}
      headerOptSize={headerOptSize}
      headerOptColor={headerOptColor}
      headerPlacementVertical={headerPlacementVertical}
      header={header}
      backgroundImage={backgroundImage}
      colorGradient={colorGradient}
      backgroundSize={backgroundSize}
      headerTextAlign={headerTextAlign?.textAlign}
    />
  ) : (
    <ImageHero
      backgroundHeight={backgroundHeight}
      headerVertical={headerVertical}
      headerHorizontal={headerHorizontal}
      headerOptAlign={headerOptAlign}
      headerOptSize={headerOptSize}
      headerOptColor={headerOptColor}
      headerPlacementVertical={headerPlacementVertical}
      header={header}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      colorGradient={colorGradient}
      backgroundSize={backgroundSize}
      headerTextAlign={headerTextAlign?.textAlign}
      backgroundColor={backgroundColor}
    />
  );
};
