import React from "react";
import css from "./Hero.module.scss";
import { AlignOptions } from "../../Elements/AlignOptions/AlignOptions.jsx";
import { setHost } from "../../../util/setHost.js";
import ScrollIndicator from "./ScrollIndicator.jsx";

export const VideoHero = (props) => {

 const host = setHost();
  const mediaURL = host + props?.backgroundImage?.data?.attributes?.url;
    const headerAlign = AlignOptions({ optionAlign: props?.headerTextAlign });

  function Element({ tag, cssClass, text }) {
    return React.createElement(
      tag?.tag,
      { className: cssClass + " " + tag?.css + " " + css.heading },
      text
    );
  }

  return (
    <>
      <div className={`${css.hero} ${props?.backgroundHeight} full`}>
        <div
          className={`${css.content} ${props?.backgroundHeight} ${props?.headerOptAlign} ${props?.headerVertical} ${props?.headerHorizontal}`}
        >
          <video className={css.video} autoPlay loop muted playsInline>
            <source src={mediaURL} type="video/mp4" />
          </video>

          <Element
            tag={props.headerOptSize}
            cssClass={`${props?.headerOptColor} ${props?.headerHorizontal} ${headerAlign}`}
            text={props?.header}
          />
        </div>
        <div
          className={css.bottomElm}
          style={{
            display:
              props?.headerPlacementVertical === "Bottom" ? "none" : "block",
          }}
        >
          {props?.backgroundSize === "Large" && (
            <>
              <div
                className={css.gradient}
                style={{
                  backgroundImage: `linear-gradient(180deg, transparent, ${props.colorGradient} 100%)`,
                }}
              >
                <ScrollIndicator className={css.scrollIndicator}/>
              </div>
              <div
                className={css.box}
                style={{ backgroundColor: props?.colorGradient }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
