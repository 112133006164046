
import css from './AlignOptions.module.scss';

export const AlignOptions = ({optionAlign}) => {

    const alignOut = (() => {
        switch (optionAlign) {
            case "Left":
                return css.left;
            case "Center":
                return css.center;
            case "Right":
                return css.right;
            default:
                return null;
        }
        })();

  return alignOut;
}
