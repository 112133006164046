import React from 'react'
import { cx } from '../../util/helper'


interface IVisible {
  on: 'desktop' | 'mobile'
  children: React.ReactNode
}

export const Visibility = ({on, children}: IVisible) => {

  return (
    <div className={cx('visibility', on === 'desktop' && 'show-in-desktop', on === 'mobile' && 'show-in-mobile')}>{children}</div>
  )
}
